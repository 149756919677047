import React from 'react';
// import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../components/Layout";
import Seo from "../components/Seo";

const msMaxWait = 2000;

const PayPage = () => {
	return (
		<Layout>
			<Seo title="Hire me" />
			<h2>Hire me</h2>
			<div className="main--centerwrapper">
				<StaticImage
					src="../images/photos/pressRes/Jesse-high-5.jpg"
					alt="Jesse Rivest in Brasilia, by Murilo Fabrino, 2021"
					placeholder="blurred"
					layout="constrained"
					className="main--centered"
					imgClassName="img--bordered-small"
					width={500}
				/>
			</div>
			<h3>I do session work</h3>
			<p>
				I got myself onto AirGigs in January 2025.
				Visit my <a href="//airgigs.com/user/jessejr">profile there</a> and
				click on <span className="italic">Show More</span> to see all my gigs/services.
			</p>


		</Layout>
	);
}

export default PayPage;
